import styled from '@emotion/styled'
import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { CardBadge, Chip, Icon, VDivider } from '@src/components/common'
import { GiftCardListSlideTypes } from '@src/components/home/gift-card-list'
import { AnimationWrapper } from '@src/components/home/gift-card-list/layout/index'
import { useAlert } from '@src/contexts/alert-context'
import { colors } from '@src/styles/theme'
import {
  AttributeTypeEnum,
  CardDivisionEnum,
  CardTypeEnum,
} from '@src/types/card'
import { isSecondaryPrivateCard } from '@src/utils/card-classification-utils'
import { formatOverMaxLengthText } from '@src/utils/format-over-maxlength-text'
import Link from 'next/link'
import { useRouter } from 'next/router'

type GiftLayoutHeaderProps = {
  giverName: string
}

export const GiftLayoutHeader = ({ giverName }: GiftLayoutHeaderProps) => {
  return (
    <AnimationWrapper>
      <VStack paddingY={24} alignItems={'center'} spacing={8}>
        <Box flexDirection="row">
          <Text
            fontSize={13}
            color={'purple.dark.200'}
            fontWeight={'bold'}
            display="inline"
          >
            {formatOverMaxLengthText(10, giverName)}님
          </Text>
          <Text fontSize={13} color={'black'} display="inline">
            으로부터 선물이 도착하였습니다!
          </Text>
        </Box>
        <Text fontSize={16} fontWeight={'bold'}>
          어떤 기프트가 들어있을까요?
        </Text>
      </VStack>
    </AnimationWrapper>
  )
}

type CheckGiftHeaderProps = {
  giverName?: string
  cardName?: string
  isMasterCard?: boolean
}

export const CardLayoutHeader = ({
  giverName,
  cardName,
  isMasterCard,
}: CheckGiftHeaderProps) => {
  return (
    <AnimationWrapper>
      <VStack padding={24} alignItems={'center'} spacing={8}>
        {isMasterCard ? (
          <CardBadge
            cardDivision={CardDivisionEnum.MASTER}
            cardType={CardTypeEnum.NORMAL}
          />
        ) : (
          <HStack>
            <Chip backgroundColor={'black'}>
              <Text fontSize={11} color={'white'} lineHeight={1}>
                From. {giverName}
              </Text>
            </Chip>
          </HStack>
        )}
        <Text fontSize={16} fontWeight={'bold'}>
          {cardName}
        </Text>
      </VStack>
    </AnimationWrapper>
  )
}

type GiftLayoutFooterProps = {
  slideType: GiftCardListSlideTypes
  cardId?: number
  division?: CardDivisionEnum
  attribute?: AttributeTypeEnum
  shoppingLink?: string
}

export const GiftAndCardLayoutFooter = ({
  slideType,
  cardId,
  division,
  attribute,
  shoppingLink,
}: GiftLayoutFooterProps) => {
  const router = useRouter()
  //  TODO: (James) this logic works only for this scope
  const isButtonActive = slideType === GiftCardListSlideTypes.Card
  const showAlert = useAlert()

  const openRouteToExchangeAlert = () => {
    showAlert({
      isUnderlineBoldText: false,
      hasHeaderIcon: false,
      content: (
        <VStack alignItems={'center'} rowGap={24}>
          <HStack columnGap={4}>
            <Icon iconName={'campaign'} size={24} variant={'outlined'} />
            <Text fontSize={16} fontWeight={'regular'}>
              알려드립니다!
            </Text>
          </HStack>
          <VStack rowGap={8} alignItems={'center'}>
            <HStack>
              <Text fontSize={13} fontWeight={'light'}>
                카드에 들어있는 금액은{' '}
              </Text>
              <HighlightedText fontSize={13} fontWeight={'bold'}>
                유찜머니로 전환한 후
              </HighlightedText>
            </HStack>
            <HStack>
              <Text fontSize={13} fontWeight={'light'}>
                인출이 가능해요.
              </Text>
            </HStack>
          </VStack>
        </VStack>
      ),
      buttons: [
        {
          label: '유찜머니 전환하기',
          onClick: () =>
            router.push({
              pathname: routes.card.exchangeCard,
              query: { id: cardId, division },
            }),
        },
      ],
    })
  }

  return (
    <HStack padding={24} justifyContent={'center'} spacing={24}>
      <Link href={isButtonActive ? shoppingLink || routes.shopping.home : ''}>
        <VStack alignItems={'center'} spacing={5}>
          <Icon
            color={isButtonActive ? 'black' : 'gray.300'}
            iconName={'barcode'}
            size={24}
          />
          <Text color={isButtonActive ? 'black' : 'gray.300'} fontSize={14}>
            쇼핑하기
          </Text>
        </VStack>
      </Link>
      <VDivider length={39} thickness={1} />
      {isSecondaryPrivateCard({
        cardDivision: division || CardDivisionEnum.SECONDARY,
        attribute,
      }) ? (
        <VStack
          alignItems={'center'}
          spacing={5}
          onClick={() =>
            showAlert({
              content:
                "해당 'Private' 상품권은 타인에게\n재선물할 수 없습니다.\n다른 상품권을 선물해보세요!",
              buttons: [{ label: '확인' }],
            })
          }
        >
          <Icon color={'gray.300'} iconName={'gift'} size={24} />
          <Text color={'gray.300'} fontSize={14}>
            선물하기
          </Text>
        </VStack>
      ) : (
        <Link
          href={
            isButtonActive
              ? {
                  pathname: routes.card.sendGift,
                  query: {
                    id: cardId,
                    division,
                  },
                }
              : ''
          }
        >
          <VStack alignItems={'center'} spacing={5}>
            <Icon
              color={isButtonActive ? 'black' : 'gray.300'}
              iconName={'gift'}
              size={24}
            />
            <Text color={isButtonActive ? 'black' : 'gray.300'} fontSize={14}>
              선물하기
            </Text>
          </VStack>
        </Link>
      )}
      <VDivider length={39} thickness={1} />
      <VStack
        alignItems={'center'}
        spacing={5}
        as={'button'}
        onClick={isButtonActive ? openRouteToExchangeAlert : undefined}
      >
        <Icon
          color={isButtonActive ? 'black' : 'gray.300'}
          iconName={'moneyWithdraw'}
          size={24}
        />
        <Text color={isButtonActive ? 'black' : 'gray.300'} fontSize={14}>
          인출하기
        </Text>
      </VStack>
    </HStack>
  )
}

const HighlightedText = styled(Text)`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    ${colors.purple.light['400']} 50%
  );
`
