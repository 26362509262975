import styled from '@emotion/styled'
import { Box } from '@src/components/basic'
import { GiftCardListSlideTypes } from '@src/components/home/gift-card-list'
import {
  BasicCardLayoutFooter,
  BasicCardLayoutHeader,
} from '@src/components/home/gift-card-list/layout/basic-card-layout'
import {
  FirstUzzimLayoutFooter,
  FirstUzzimLayoutHeader,
} from '@src/components/home/gift-card-list/layout/first-uzzim-card-layout'
import {
  CardLayoutHeader,
  GiftAndCardLayoutFooter,
  GiftLayoutHeader,
} from '@src/components/home/gift-card-list/layout/gift-and-card-layout'
import { CardDivisionEnum, SwipeCardSwipeListItemProps } from '@src/types/card'
import React from 'react'

type GiftCardListLayoutProps = {
  children: React.ReactNode
  activeSlideItem: SwipeCardSwipeListItemProps
}

export const GiftCardListLayout = ({
  children,
  activeSlideItem,
}: GiftCardListLayoutProps) => {
  return (
    <>
      <Box height={80}>
        <GiftCardListHeader activeSlideItem={activeSlideItem} />
      </Box>
      {children}
      <Box height={120}>
        <GiftCardListFooter activeSlideItem={activeSlideItem} />
      </Box>
    </>
  )
}

const GiftCardListHeader = ({
  activeSlideItem,
}: Omit<GiftCardListLayoutProps, 'children'>) => {
  if (activeSlideItem.cardDivision === CardDivisionEnum.MASTER) {
    return (
      <CardLayoutHeader
        isMasterCard
        cardName={activeSlideItem.cardName || ''}
      />
    )
  }

  switch (activeSlideItem.slideType) {
    case GiftCardListSlideTypes.Gift:
      return <GiftLayoutHeader giverName={activeSlideItem.giverName} />
    case GiftCardListSlideTypes.Card:
      return (
        <CardLayoutHeader
          giverName={activeSlideItem.giverName}
          cardName={activeSlideItem.cardName || ''}
        />
      )
    case GiftCardListSlideTypes.FirstUzzim:
      return <FirstUzzimLayoutHeader />
    case GiftCardListSlideTypes.Basic:
      return <BasicCardLayoutHeader />
    default:
      return <div>error</div>
  }
}

const GiftCardListFooter = ({
  activeSlideItem,
}: Omit<GiftCardListLayoutProps, 'children'>) => {
  switch (activeSlideItem.slideType) {
    case GiftCardListSlideTypes.Card:
    case GiftCardListSlideTypes.Gift:
      return (
        <GiftAndCardLayoutFooter
          slideType={activeSlideItem.slideType}
          cardId={activeSlideItem.cardId}
          division={activeSlideItem.cardDivision}
          attribute={activeSlideItem.attribute}
          shoppingLink={activeSlideItem.shoppingLink}
        />
      )
    case GiftCardListSlideTypes.FirstUzzim:
      return <FirstUzzimLayoutFooter />
    case GiftCardListSlideTypes.Basic:
      return <BasicCardLayoutFooter />
    default:
      return <div>error</div>
  }
}

//NOTE: (James) Please feel free to whatever animation you'd like to see
export const AnimationWrapper = styled.div`
  animation: ease-in-out show 1s;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
