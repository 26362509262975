import { useMainBanner } from '@api/hooks/banner/use-main-banner'
import { useSwipeCardList } from '@api/hooks/card/use-swipe-card-list'
import { useChannelComponentsQuery } from '@api/hooks/channel/use-channel-components'
import { Box, VStack } from '@src/components/basic'
import { HDivider, LoadingSpinner } from '@src/components/common'
import { Footer } from '@src/components/home/footer'
import { GiftCardList } from '@src/components/home/gift-card-list'
import { RecentGiftList } from '@src/components/home/recent-gift-list'
import { ChannelComponentList } from '@src/components/shopping/channel-component-list'
import Image from 'next/image'
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'

const Home = () => {
  const { ref: observerRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const swipeCardList = useSwipeCardList()
  const channelComponentsQuery = useChannelComponentsQuery(false, {
    enabled: inView,
  })
  const mainBanner = useMainBanner()

  if (!swipeCardList.isSuccess) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Box paddingX={16} paddingY={32} backgroundColor={'gray.100'}>
        <VStack alignItems={'center'}>
          <GiftCardList swipeCardList={swipeCardList.data} />
        </VStack>
      </Box>
      <HDivider backgroundColor="gray.100" />
      <Box ref={observerRef}>
        {mainBanner.data && (
          <Link href={mainBanner.data.endUrl}>
            {/*  NOTE: (James) need to discuss further about how we want to manage image that wants 100% height and 100% width*/}
            <Image
              src={mainBanner.data.imageUrl}
              alt={mainBanner.data.title}
              priority
              width={1000}
              height={1000}
              style={{ height: '100%', width: '100%' }}
            />
          </Link>
        )}
      </Box>
      <RecentGiftList />
      <HDivider backgroundColor="gray.100" />
      {inView &&
        (!channelComponentsQuery.isSuccess ? (
          <LoadingSpinner />
        ) : (
          <ChannelComponentList components={channelComponentsQuery.data} />
        ))}
      <Footer />
    </>
  )
}

export default Home
