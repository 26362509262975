import { Box } from '@src/components/basic'
import { aws } from '@src/config'
import Image from 'next/legacy/image'

const basicCardImageUrl = `${aws.imageBaseUrl}/home/basic-cards.png`

export const BasicCard = () => {
  return (
    <Box
      borderRadius={16}
      backgroundColor={'gray.100'}
      width={263}
      height={216}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Image
        src={basicCardImageUrl}
        alt={'basic card'}
        layout={'fixed'}
        width={200}
        height={190}
      />
    </Box>
  )
}
