import { useGiftHistory } from '@api/hooks/gift/use-gift-history'
import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Icon } from '@src/components/common'
import { LoadingSpinner } from '@src/components/common'
import { ReceivedGift } from '@src/components/my/gift/received-history/received-gift'
import { SentGift } from '@src/components/my/gift/sent-history/sent-gift'
import { isLogin } from '@src/utils/is-login'
import Link from 'next/link'
import { NoRecentGift } from './no-recent-gift'

export const RecentGiftList = () => {
  const giftHistory = useGiftHistory(1, 3)

  if (!isLogin()) {
    return null
  }

  if (giftHistory.isLoading || !giftHistory.data) return <LoadingSpinner />

  return (
    <Box>
      <HStack
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingTop={16}
        paddingX={16}
      >
        <Text fontSize={16} fontWeight={'bold'}>
          최근 선물 내역
        </Text>
        <Link href={routes.my.giftReceivedHistory}>
          <HStack alignItems={'center'} spacing={5}>
            <Text color={'gray.500'} fontSize={12} fontWeight={'semilight'}>
              전체 보기
            </Text>
            <Icon iconName={'next'} color={'gray.500'} size={12} />
          </HStack>
        </Link>
      </HStack>
      {giftHistory.data.total === 0 && <NoRecentGift />}
      {giftHistory.data.items.length !== 0 && (
        <VStack padding={16} spacing={24} alignItems={'center'}>
          {giftHistory.data.total > 0 &&
            giftHistory.data.items.map((giftHistory) =>
              !!giftHistory.receiverName ? (
                <SentGift
                  giftHistory={giftHistory}
                  key={giftHistory.cardHistoryId}
                />
              ) : (
                <ReceivedGift
                  giftHistory={giftHistory}
                  key={giftHistory.cardHistoryId}
                />
              )
            )}
        </VStack>
      )}
    </Box>
  )
}
