import { HStack, Text, VStack } from '@src/components/basic'
import { Chip, UnderlineButton } from '@src/components/common'
import { AnimationWrapper } from '@src/components/home/gift-card-list/layout/index'
import React from 'react'
import { useHasGiftList } from '@api/hooks/card/use-has-gift-list'
import { isLogin } from '@src/utils/is-login'
import { useNotImplementedAlert } from '@src/hook/use-not-implemented-alert'
import Link from 'next/link'
import { routes } from '@src/common/constants/routes'
import { useRouter } from 'next/router'

export const FirstUzzimLayoutHeader = () => {
  return (
    <AnimationWrapper>
      <VStack padding={24} alignItems={'center'} spacing={8}>
        <Chip backgroundColor={'black'}>
          <Text fontSize={11} color={'white'} lineHeight={1}>
            Uzzim
          </Text>
        </Chip>
        <Text fontSize={16} fontWeight={'bold'}>
          {isLogin() ? '유찜이 처음이신가요?' : '환영합니다!'}
        </Text>
      </VStack>
    </AnimationWrapper>
  )
}

export const FirstUzzimLayoutFooter = () => {
  const hasGiftList = useHasGiftList()
  const router = useRouter()

  return (
    <AnimationWrapper>
      <VStack padding={[0, 24, 24, 24]} alignItems={'center'} spacing={8}>
        {isLogin() ? (
          <Chip backgroundColor={'purple.dark.200'} width={216} padding={'8px'}>
            <HStack
              justifyContent={'center'}
              width={'100%'}
              spacing={2}
              as={'button'}
              onClick={() => router.push(routes.studio.uzzimStudio.home)}
            >
              <Text fontSize={15} color={'white'} lineHeight={1.6}>
                나만의 기프트 만들기
              </Text>
            </HStack>
          </Chip>
        ) : (
          <Link href={routes.my.login}>
            <Chip
              backgroundColor={'purple.dark.200'}
              width={216}
              padding={'8px'}
            >
              <HStack justifyContent={'center'} width={'100%'} spacing={2}>
                <Text fontSize={15} color={'white'} lineHeight={1.6}>
                  가입하고 선물 확인하기
                </Text>
              </HStack>
            </Chip>
          </Link>
        )}
        {hasGiftList.data && (
          <UnderlineButton
            label={'아직 확인하지 않은 유찜 선물이 있어요!'}
            fontSize={13}
            color={'gray.800'}
          />
        )}
      </VStack>
    </AnimationWrapper>
  )
}
