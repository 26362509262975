import styled from '@emotion/styled'
import { GiftCard } from '@src/components/home/gift-card-list/card'
import { GiftCardListLayout } from '@src/components/home/gift-card-list/layout'

import { colors } from '@src/styles/theme'
import { SwipeCardSwipeListItemProps } from '@src/types/card'
import React, { useCallback, useEffect, useState } from 'react'

import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LoadingSpinner } from '@src/components/common'
import { parseSize } from '@src/components/basic/utils'

export enum GiftCardListSlideTypes {
  Gift = 'gift',
  Card = 'card',
  Basic = 'basic',
  None = 'none',
  FirstUzzim = 'firstUzzim',
}

type GiftCardListProps = {
  swipeCardList: SwipeCardSwipeListItemProps[]
}

const initialIndex = 0

export const GiftCardList = ({ swipeCardList }: GiftCardListProps) => {
  const [activeSlideItem, setActiveSlideItem] =
    useState<SwipeCardSwipeListItemProps>(swipeCardList[initialIndex])

  const handleSwiperChange = (swiperIndex: number) => {
    setActiveSlideItem(swipeCardList[swiperIndex])
  }

  const initiateSwiper = useCallback(() => {
    setActiveSlideItem(swipeCardList[initialIndex])
  }, [swipeCardList])

  useEffect(() => {
    initiateSwiper()
  }, [initiateSwiper])

  return (
    <StyledCardContainer>
      <GiftCardListLayout activeSlideItem={activeSlideItem}>
        <Swiper
          slidesPerView={1.25}
          spaceBetween={24}
          centeredSlides={true}
          onSwiper={(swiper) => {
            handleSwiperChange(swiper.realIndex)
          }}
          onSlideChange={(swiper) => {
            handleSwiperChange(swiper.realIndex)
          }}
        >
          {swipeCardList.map((gift) => (
            <SwiperSlide key={gift.cardId}>
              <SlideContainer>
                <GiftCardWrapper>
                  {/*TODO: (James) check for undefined cases*/}
                  {gift ? <GiftCard gift={gift} /> : <LoadingSpinner />}
                </GiftCardWrapper>
              </SlideContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </GiftCardListLayout>
    </StyledCardContainer>
  )
}

const GiftCardWrapper = styled.div`
  margin: 10px 0;
`

const SlideContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledCardContainer = styled.section`
  background-color: ${colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.32);
  border-radius: 16px;
  width: ${parseSize(330)};
  max-width: 100%;
`
