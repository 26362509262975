import { CardClient } from '@api/card-client'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '@api/constants/query-keys'
import { isLogin } from '@src/utils/is-login'

export const useGiftHistory = (page: number, size: number) => {
  return useQuery(
    [queryKeys.gift.giftHistory, page, size],
    () => CardClient.giftHistory(page, size),
    {
      keepPreviousData: true,
      enabled: isLogin(),
    }
  )
}
