import styled from '@emotion/styled'
import { homeImages } from '@src/common/constants/images'
import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { Icon } from '@src/components/common'
import { colors } from '@src/styles/theme'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'

export const NoRecentGift = () => {
  const router = useRouter()

  return (
    <VStack
      height={300}
      alignItems={'center'}
      justifyContent={'center'}
      paddingY={40}
      rowGap={48}
    >
      <VStack alignItems={'center'} rowGap={20}>
        <Box
          backgroundColor={'rgba(154, 100, 222, 0.4)'}
          filter={'blur(30px)'}
          size={86}
          position={'absolute'}
        />
        <Box position={'relative'} width={86} height={86}>
          <Image
            src={homeImages.noRecentGift}
            alt={'최근 선물받은 내역이 없습니다.'}
            layout={'fill'}
          />
        </Box>
        <Text
          lineHeight={1.3}
          fontSize={13}
          fontWeight={'regular'}
          textAlign={'center'}
        >
          최근 선물 내역이 없습니다.{'\n'}유찜 기프트로 마음을 나눠보세요.
        </Text>
      </VStack>
      <StyledButton onClick={() => router.push(routes.my.cards)}>
        <HStack columnGap={4}>
          <Icon
            iconName={'gift'}
            size={20}
            color={'purple.dark.200'}
            variant={'outlined'}
          />
          <Text fontSize={13} fontColor={'purple.dark.200'}>
            선물 가능한 기프트 보러가기
          </Text>
        </HStack>
      </StyledButton>
    </VStack>
  )
}

const StyledButton = styled.button`
  border: 1px solid ${colors.purple.dark[200]};
  border-radius: ${parseSize(22)};
  padding: 12px 50px;
`
