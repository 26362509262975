import { api } from '.'
import { BannerResponseDto } from '@src/types/banner'

class BannerClient {
  static async getBanner() {
    return await api.uzzimClient.get<BannerResponseDto | null>(`/api/banner`)
  }
}

export { BannerClient }
