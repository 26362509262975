import { useEffect, useRef } from 'react'

export const useVideoControl = (entry: IntersectionObserverEntry) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (videoRef.current && entry && entry.isIntersecting) {
      videoRef.current.play()
      return
    }
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }, [entry])

  return videoRef
}
