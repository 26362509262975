import { Color } from '@src/components/basic/types'
import { Box } from '@src/components/basic'

type GalaxyStarProps = {
  color: Color
  size?: number
}
export const GalaxyStar = ({ color, size = 35 }: GalaxyStarProps) => {
  return (
    <Box size={size}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 17.0323C6.6272 17.0326 12.0819 22.405 12.0819 29.0323V34.0646H12.0829V29.0323C12.0829 22.4051 17.5376 17.0326 24.1648 17.0323C17.5376 17.032 12.0829 11.6596 12.0829 5.0323V0H12.0819V5.03229C12.0819 11.6595 6.6272 17.032 0 17.0323Z"
          fill={color}
        />
      </svg>
    </Box>
  )
}
