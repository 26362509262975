import { CardClient } from '@api/card-client'
import { GiftCardListSlideTypes } from '@src/components/home/gift-card-list'
import { useQuery } from '@tanstack/react-query'
import {
  SwipeCardListResponseDto,
  SwipeCardSwipeListItemProps,
} from '@src/types/card'
import { queryKeys } from '@api/constants/query-keys'

export const useSwipeCardList = () => {
  return useQuery<
    SwipeCardListResponseDto,
    Error,
    SwipeCardSwipeListItemProps[]
  >([queryKeys.card.swipeCardList], () => CardClient.getSwipeCardList(), {
    select: (data) => {
      const flattenedList: Omit<SwipeCardSwipeListItemProps, 'slideType'>[] = [
        ...data.swipeGiftResponseList,
        ...data.swipeCardResponseList,
      ]
      const slideTypedSwipeCardList = flattenedList.map((response) => {
        if (response.cardName) {
          return {
            ...response,
            slideType: GiftCardListSlideTypes.Card,
          }
        }
        return {
          ...response,
          slideType: GiftCardListSlideTypes.Gift,
        }
      })
      const basicCardType = {
        cardId: 0,
        senderCardId: 0,
        giverName: GiftCardListSlideTypes.Basic,
        slideType: GiftCardListSlideTypes.Basic,
      }
      const uzzimFirstCardType = {
        cardId: 1,
        senderCardId: 1,
        giverName: GiftCardListSlideTypes.FirstUzzim,
        slideType: GiftCardListSlideTypes.FirstUzzim,
      }
      if (data.swipeCardResponseList.length === 0) {
        return [...slideTypedSwipeCardList, uzzimFirstCardType, basicCardType]
      }
      return [...slideTypedSwipeCardList, basicCardType]
    },
  })
}
