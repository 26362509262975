import { useQuery } from '@tanstack/react-query'
import { SwipeCardListResponseDto } from '@src/types/card'
import { CardClient } from '@api/card-client'
import { queryKeys } from '@api/constants/query-keys'

export const useHasGiftList = () => {
  return useQuery<SwipeCardListResponseDto, Error, boolean>(
    [queryKeys.card.swipeCardList],
    () => CardClient.getSwipeCardList(),
    {
      select: (data) => {
        const giftList = data.swipeGiftResponseList
        return giftList.length !== 0
      },
    }
  )
}
