import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Chip, Icon } from '@src/components/common'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React from 'react'
import { GiftCardProps } from '@src/components/home/gift-card-list/card/index'
import { aws } from '@src/config'

export const giftAnimationUrl = `${aws.imageBaseUrl}/home/gift-animation.gif`

export const Gift = ({ gift }: GiftCardProps) => {
  return (
    <Box
      borderRadius={16}
      boxShadow={'0px 0px 10px rgba(0, 0, 0, 0.32)'}
      backgroundColor={'gray.100'}
      marginY={8}
      height={216}
    >
      <VStack alignItems={'center'} justifyContent={'center'}>
        <Box
          width={263}
          height={167}
          borderRadius={16}
          backgroundColor={'white'}
        >
          <HStack alignItems={'center'} justifyContent={'center'}>
            <Image
              src={giftAnimationUrl}
              width={167}
              height={167}
              layout={'fixed'}
              alt="선물상자"
            />
          </HStack>
        </Box>
        <HStack padding={10} justifyContent={'center'}>
          <Link
            href={{
              pathname: routes.card.receiveGift,
              query: {
                id: gift.cardId,
              },
            }}
          >
            <Chip borderColor={'purple.dark.200'} padding={'8px 12px'}>
              <HStack spacing={1} width={'100%'}>
                <Icon
                  iconName={'verified'}
                  size={12}
                  color={'purple.dark.200'}
                  variant={'outlined'}
                />
                <Text fontSize={11} color={'purple.dark.200'} lineHeight={1}>
                  선물 확인하기
                </Text>
              </HStack>
            </Chip>
          </Link>
        </HStack>
      </VStack>
    </Box>
  )
}
