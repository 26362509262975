import { GiftCardListSlideTypes } from '@src/components/home/gift-card-list'
import { BasicCard } from '@src/components/home/gift-card-list/card/basic-card'
import { FirstUzzimCard } from '@src/components/home/gift-card-list/card/first-uzzim-card'
import { Gift } from '@src/components/home/gift-card-list/card/gift'
import { ReceivedCard } from '@src/components/home/gift-card-list/card/received-card'
import React from 'react'
import { SwipeCardSwipeListItemProps } from '@src/types/card'

export type GiftCardProps = {
  gift: SwipeCardSwipeListItemProps
}

export const GiftCard = ({ gift }: GiftCardProps) => {
  switch (gift.slideType) {
    case GiftCardListSlideTypes.Gift:
      return <Gift gift={gift} />
    case GiftCardListSlideTypes.Card:
      return <ReceivedCard gift={gift} />
    case GiftCardListSlideTypes.FirstUzzim:
      return <FirstUzzimCard />
    case GiftCardListSlideTypes.Basic:
      return <BasicCard />
    default:
      //TODO: (James) think of an error handling card section
      return <div>error card</div>
  }
}
