import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Chip, Icon, UnderlineButton } from '@src/components/common'
import { AnimationWrapper } from '@src/components/home/gift-card-list/layout/index'
import { isLogin } from '@src/utils/is-login'
import Link from 'next/link'

export const BasicCardLayoutHeader = () => {
  return (
    <AnimationWrapper>
      <VStack padding={24} alignItems={'center'} spacing={8}>
        <Text fontSize={13} color={'purple.dark.200'}>
          {isLogin()
            ? '이런, 선물할 기프트가 없으신가요?'
            : '선물할 기프트가 없어도 괜찮아요'}
        </Text>
        <Text fontSize={16} fontWeight={'bold'}>
          {isLogin()
            ? '유찜이 선물할 기프트를 준비했어요!'
            : '유찜이 준비한 기프트를 선물해 보세요!'}
        </Text>
      </VStack>
    </AnimationWrapper>
  )
}

export const BasicCardLayoutFooter = () => {
  return (
    <AnimationWrapper>
      <VStack padding={[0, 24, 24, 24]} alignItems={'center'} spacing={8}>
        <Link href={isLogin() ? routes.card.sendGiftBasic : routes.my.login}>
          <Box as={'button'}>
            <Chip
              backgroundColor={'white'}
              borderColor={'purple.dark.200'}
              width={263}
              padding={'10px'}
            >
              <HStack justifyContent={'center'} width={'100%'} spacing={2}>
                <Icon iconName={'gift'} size={18} />
                <Text fontSize={13} color={'purple.dark.200'}>
                  {isLogin() ? '기본 기프트 선물하기' : '로그인하고 선물하기'}
                </Text>
              </HStack>
            </Chip>
          </Box>
        </Link>
        {isLogin() && (
          <Link href={routes.my.cards}>
            <UnderlineButton
              label={'괜찮아요, 내 기프트로 선물할게요'}
              fontSize={13}
              color={'gray.800'}
            />
          </Link>
        )}
      </VStack>
    </AnimationWrapper>
  )
}
