import { globalImages } from '@src/common/constants/images'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { GalaxyStar } from '@src/components/home/gift-card-list/card/galaxy-star'
import { AnimationWrapper } from '@src/components/home/gift-card-list/layout'
import { colors } from '@src/styles/theme'
import { isLogin } from '@src/utils/is-login'
import Image from 'next/legacy/image'

export const FirstUzzimCard = () => {
  return (
    <AnimationWrapper>
      <Box marginY={8} height={216}>
        {isLogin() ? <FirstUzzimCardImage /> : <FirstUzzimWelcome />}
      </Box>
    </AnimationWrapper>
  )
}

const FirstUzzimWelcome = () => {
  return (
    <VStack
      height={'100%'}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={16}
    >
      <HStack position={'relative'}>
        <Text
          fontFamily={'PuradakGentleGothicR'}
          fontColor={'purple.dark.200'}
          fontSize={25}
          whiteSpace="nowrap"
        >
          선물 고민은 이제 그만!
        </Text>
        <Box position={'absolute'} top={-30} right={-5}>
          <GalaxyStar color={colors.purple.dark['200']} />
        </Box>
      </HStack>
      <VStack alignItems={'center'} position={'relative'}>
        <Box position={'absolute'} top={-13} left={-27}>
          <GalaxyStar color={'#A432FE'} size={25} />
        </Box>
        <HStack>
          <Text
            fontColor={'#A432FE'}
            fontSize={20}
            fontFamily={'PuradakGentleGothicR'}
          >
            받는 사람
          </Text>
          <Text
            fontColor={'purple.dark.200'}
            fontSize={20}
            fontFamily={'PuradakGentleGothicR'}
          >
            이 직접 고르는
          </Text>
        </HStack>
        <Text
          fontColor={'purple.dark.200'}
          fontSize={20}
          fontFamily={'PuradakGentleGothicR'}
        >
          신개념 선물 서비스
        </Text>
      </VStack>
      <Image
        src={globalImages.logo}
        width={80}
        height={24}
        layout={'fixed'}
        alt="유찜 메인 로고"
        priority
      />
    </VStack>
  )
}

const FirstUzzimCardImage = () => {
  return (
    <svg
      width="230"
      height="198"
      viewBox="0 0 230 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="114" cy="98" r="80" fill="#E9D8FF" />
      <path
        d="M53.5 65.2903C53.5 62.0884 56.0726 59.5 59.2371 59.5H167.763C170.927 59.5 173.5 62.0884 173.5 65.2903V130.71C173.5 133.912 170.927 136.5 167.763 136.5H59.2371C56.0726 136.5 53.5 133.912 53.5 130.71V65.2903Z"
        fill="white"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.843 85C111.754 85 110.87 85.827 110.87 86.8571V96.1429H101.006C99.9165 96.1429 99.0332 96.9699 99.0332 98C99.0332 99.0301 99.9165 99.8571 101.006 99.8571H110.87V109.143C110.87 110.173 111.754 111 112.843 111C113.932 111 114.816 110.173 114.816 109.143V99.8571H124.68C125.769 99.8571 126.653 99.0301 126.653 98C126.653 96.9699 125.769 96.1429 124.68 96.1429H114.816V86.8571C114.816 86.4364 114.665 86.0446 114.412 85.7254C114.051 85.2757 113.483 85 112.843 85Z"
        fill="#D3D3D3"
      />
      <rect
        x="144.719"
        y="183.512"
        width="14.5935"
        height="14.4878"
        rx="1.5"
        fill="#E9D8FF"
      />
      <rect
        width="8.5041"
        height="8.46004"
        rx="0.5"
        transform="matrix(0.926881 -0.375356 0.380063 0.92496 218.902 78.7964)"
        fill="#B074FD"
      />
      <rect
        width="9.71897"
        height="9.66862"
        rx="0.5"
        transform="matrix(0.926881 -0.375356 0.380063 0.92496 81.4805 160.602)"
        fill="#B074FD"
      />
      <ellipse
        cx="148.367"
        cy="39.8413"
        rx="6.08062"
        ry="6.03659"
        fill="#B074FD"
      />
      <ellipse
        cx="34.0516"
        cy="54.3294"
        rx="7.29675"
        ry="7.2439"
        fill="#CBA3FF"
      />
      <path
        d="M81.0483 0.743209C81.2411 0.411695 81.72 0.411695 81.9127 0.743209L86.3095 8.30352C86.5034 8.63685 86.2629 9.05488 85.8773 9.05488H77.0837C76.6981 9.05488 76.4577 8.63685 76.6515 8.30352L81.0483 0.743209Z"
        fill="#CDA6FE"
      />
      <path
        d="M9.59059 140.918C9.21727 139.429 10.6067 138.098 12.0918 138.521L25.6545 142.387C27.1399 142.81 27.6069 144.671 26.4951 145.736L16.3418 155.464C15.23 156.529 13.3733 156 12.9999 154.511L9.59059 140.918Z"
        fill="#E9D8FF"
      />
    </svg>
  )
}
