import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import {
  BrandName,
  Card,
  Chip,
  MoneyText,
  StretchedLink,
} from '@src/components/common'
import { CardDivisionEnum } from '@src/types/card'
import { GiftReceiveHistoryResponseDto } from '@src/types/gift'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'

type GiftHistoryProps = {
  giftHistory: GiftReceiveHistoryResponseDto
}

export const ReceivedGift = ({ giftHistory }: GiftHistoryProps) => {
  return (
    <StretchedLink
      href={{
        pathname: routes.my.giftReceivedHistory,
        query: {
          cardHistoryId: giftHistory.cardHistoryId,
          senderCardId: giftHistory.senderCardId,
          //FIXME: (James) card division is not ready from server. use it when it's ready
          cardDivision: CardDivisionEnum.SECONDARY,
        },
      }}
      scroll={false}
    >
      <Box border={['1px', 'solid', 'gray.100']} width={'100%'} minHeight={120}>
        <VStack>
          <Box backgroundColor={'gray.100'}>
            <HStack
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingX={8}
              paddingY={6}
            >
              <Text fontSize={11}>From. {giftHistory.giverName}</Text>
              <Text fontSize={12} color={'gray.500'}>
                {formatDate(giftHistory.sendDate, DateFormatTypes.DateAndTime)}
              </Text>
            </HStack>
          </Box>
          <HStack padding={8}>
            <Box
              width={76}
              height={76}
              display={'flex'}
              flexShrink={0}
              padding={1}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Card
                cardComponentType={'list'}
                imageUrl={giftHistory.thumbnailUrl}
                horizontal={giftHistory.horizontal}
                width={72}
                height={48}
                alt={giftHistory.cardName}
              />
            </Box>
            <VStack
              width={'100%'}
              height={'100%'}
              justifyContent={'space-between'}
              paddingX={6}
              paddingY={10}
            >
              <BrandName value={giftHistory.brandName} />
              <Text color={'gray.800'} fontSize={13}>
                {giftHistory.cardName}
              </Text>
              <HStack justifyContent={'flex-end'} spacing={8}>
                <Chip>
                  <Text
                    color={'gray.800'}
                    fontSize={11}
                    fontWeight={'light'}
                    lineHeight={1}
                  >
                    선물금액
                  </Text>
                </Chip>
                <MoneyText moneyAmount={giftHistory.giftAmount} />
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </StretchedLink>
  )
}
