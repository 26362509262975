import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Card, Chip, Icon, MoneyText, Video } from '@src/components/common'
import { GiftCardProps } from '@src/components/home/gift-card-list/card/index'
import { useVideoControl } from '@src/hook/use-video-control'
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'

export const ReceivedCard = ({ gift }: GiftCardProps) => {
  const {
    ref: observerRef,
    entry,
    inView,
  } = useInView({
    threshold: 1,
  })
  const videoRef = useVideoControl(entry as IntersectionObserverEntry)

  return (
    <Box
      borderRadius={16}
      boxShadow={'0px 0px 10px rgba(0, 0, 0, 0.32)'}
      backgroundColor={'gray.100'}
      marginY={8}
      height={216}
    >
      <VStack alignItems={'center'}>
        <Box
          width={263}
          height={167}
          borderRadius={16}
          backgroundColor={'white'}
          overflow={'hidden'}
          ref={observerRef}
        >
          <HStack justifyContent={'center'} height={'100%'}>
            <Link
              href={{
                pathname: routes.my.cards,
                query: {
                  id: gift.cardId,
                  division: gift.cardDivision,
                },
              }}
            >
              <Box width={263} height={167} centered>
                {gift.isImage ? (
                  <Card
                    cardComponentType={'list'}
                    imageUrl={gift.thumbnailUrl || ''}
                    horizontal={gift.horizontal}
                    width={263}
                    height={167}
                    alt={gift.cardName}
                  />
                ) : (
                  <Video
                    ref={videoRef}
                    mediaUrl={gift.mediaUrl || ''}
                    thumbnail={gift.thumbnailUrl || ''}
                    isHorizontal={gift.horizontal}
                    inView={inView}
                    cardComponentType={'list'}
                  />
                )}
              </Box>
            </Link>
          </HStack>
        </Box>
        <HStack
          padding={10}
          justifyContent={'space-between'}
          width={'100%'}
          alignItems={'center'}
        >
          <MoneyText
            moneyFontSize={22}
            unitFontSize={16}
            moneyAmount={gift.totalAmount || 0}
          />
          <Link
            href={{
              pathname: routes.card.chargeCard,
              query: {
                cardId: gift.cardId,
                cardDivision: gift.cardDivision,
              },
            }}
          >
            <Chip borderColor={'gray.200'} backgroundColor={'white'}>
              <HStack spacing={1} width={'100%'}>
                <Icon iconName={'fileUpload'} size={12} variant={'outlined'} />
                <Text fontSize={11} lineHeight={1}>
                  충전하기
                </Text>
              </HStack>
            </Chip>
          </Link>
        </HStack>
      </VStack>
    </Box>
  )
}
